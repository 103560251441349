<template>
  <v-row>
    <v-col md="11">
      <v-expansion-panels v-if="getUserWarehouses" flat>
        <template v-if="!getUserWarehouses.length">
          <div class="mt-10">
            <h1>No Warehouse Found</h1>
          </div>
        </template>
        <template v-else>
          <ExpansionPanel
            v-for="(item, index) in getUserWarehouses"
            :key="index"
            :warehouseDetails="item"
          />
        </template>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import ExpansionPanel from "@/own/components/warehouseManagement/mainConfiguration/outbound/ExpansionPanel.vue";

export default {
  name: "OutboundConfiguration",
  components: { ExpansionPanel },
  computed: {
    getUserWarehouses: function () {
      return this.$store.getters.getUserWareHouses;
    },
  },
};
</script>
